
import Vue from "vue";

export default Vue.extend({
  name: "indexPage",
  head() {
    return {
      title: "NSP - сайт независимого дистрибьютора компании",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "НСП (NSP) в Мире Здоровья. Витамины и добавки без 40% розничной торговой надбавки по Карте Партнёра (оформление и консультация без оплаты). Профессиональная линейка продукции, высокое качество. Официальные поставки из США по всему миру, без посредников.",
        },
      ],
    };
  },
});
