
import Vue from "vue";
import Menu from "./Menu.vue";
import MobileMenu from "./MobileMenu.vue";

export default Vue.extend({
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  components: { MobileMenu, Menu },
});
