import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fd50783&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=4fd50783&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd50783",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/Users/philipsh/Documents/projects/world-nsp/front/components/Header.vue').default,Button: require('/Users/philipsh/Documents/projects/world-nsp/front/components/Button.vue').default,Footer: require('/Users/philipsh/Documents/projects/world-nsp/front/components/Footer.vue').default})
