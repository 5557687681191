import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=6c2236f9&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=6c2236f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2236f9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/Users/philipsh/Documents/projects/world-nsp/front/components/Footer.vue').default})
