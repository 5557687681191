// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/footer-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section[data-v-6c2236f9]{margin-left:auto;margin-right:auto;box-sizing:border-box;width:100%}.footer[data-v-6c2236f9]{box-sizing:content-box;display:flex;flex-direction:column;align-items:center;justify-content:flex-start;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;padding-left:54px;padding-right:54px;padding-top:40px;padding-bottom:40px;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))}@media (min-width: 768px){.footer[data-v-6c2236f9]{padding-left:150px;padding-right:150px;padding-top:75px;padding-bottom:75px}}@media (min-width: 1024px){.footer[data-v-6c2236f9]{padding-left:270px;padding-right:270px;padding-top:120px;padding-bottom:120px}}@media (min-width: 1280px){.footer[data-v-6c2236f9]{padding-left:320px;padding-right:320px;padding-top:140px;padding-bottom:140px}}.footer-divider[data-v-6c2236f9]{width:100%;border-top-width:1px;--tw-border-opacity:1;border-color:rgb(255 255 255 / var(--tw-border-opacity));opacity:35%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
